import { Button, Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { dispatchtoast, makeLabel, setLocalStorageItem } from "../../Utils";
import OTPInput from "react-otp-input";
import CommonInput from "../../CommonComponents/CommonInput";
import { useLocation, useNavigate } from "react-router-dom";
import { expiryNotify, vendorVerifyTwoFA } from "./AuthFun";
import { Images } from "../../ImageController";

const TwoFa = () => {
  const formref = useRef();
  const location = useLocation();
  const { qr, email, accessToken, verificationToken } = location?.state || {};
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  useEffect(() => {
    if (!location?.state) {
      navigate(-1);
    }
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    formref.current.setFieldValue("email", email);
    //eslint-disable-next-line
  }, []);
  const checkPlanExpiry = () => {
    expiryNotify().then((res) => {
      if (res.status) {
        dispatchtoast(res.message);
      }
    });
  };
  const handleVerify = (val) => {
    setBtnLoading(true);
    let payload = {
      verificationToken: verificationToken,
      token: val.otp,
    };
    vendorVerifyTwoFA(payload)
      .then((res) => {
        if (res.status) {
          checkPlanExpiry();
          setLocalStorageItem("accessToken", res.token?.token);
          setLocalStorageItem("userDetails", {
            vendorId: res?.token?.vendorId,
            isAuthenticated: res?.token?.isAuthenticated,
          });

          navigate("/dashboard");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setBtnLoading(false));
  };

  return (
    <>
      <div className="flex justify-center bg-[#F7F6FF] font-poppins items-center h-screen">
        <div
          style={{ boxShadow: " 0px 0px 35px 0px #9AA1AB26" }}
          className="bg-white rounded-md m-4 w-[500px] px-8 py-8  "
        >
          <div className="text-secondary flex justify-center items-center w-1/2  m-auto   px-4 py-2 rounded-lg">
            <img
              loading="lazy"
              src={Images.weblogocolor}
              className="flex justify-center items-center"
              alt="weblogo"
            />
          </div>
          <p className="mt-4 text-center font-semibold text-[#3D4465]">
            Enter two-factor authentication code
          </p>
          {qr && (
            <div>
              <div className="mt-2">
                <p className="text-center">Qr Code</p>
                <img
                  loading="lazy"
                  alt="QR Code"
                  className="w-1/2 mt-2 m-auto"
                  src={qr}
                />
              </div>
            </div>
          )}
          <Form ref={formref} form={form} onFinish={(val) => handleVerify(val)}>
            <Form.Item
              name={"email"}
              labelCol={{ span: 24 }}
              label={makeLabel("Email")}
            >
              <CommonInput disabled={true} placeHolder="Email" />
            </Form.Item>
            <Form.Item
              name={"otp"}
              rules={[
                {
                  required: true,
                  message: "Authentication Code Is Required",
                },
              ]}
              labelCol={{ span: 24 }}
              label={makeLabel("Authentication Code")}
            >
              <OTPInput
                //   value={otp}
                //   onChange={setOtp}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  width: "80%",
                  marginRight: "10px",
                  height: "40px",
                  border: "2px  solid var(--primary)",
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={btnLoading}
                type="primary"
                iconPosition="end"
                htmlType="submit"
                className="font-poppins w-full mt-4  text-white font-bold rounded-lg bg-secondary"
              >
                Verify Code
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default TwoFa;
